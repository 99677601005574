import { Transition } from '@headlessui/react';
import { DOCUMENTATION_URL } from '@lib';
import IconOutline from './IconOutline';
import { Subtitle1Text } from './Text';

interface Props {
  id: string;
  target: string;
  children?: any;
}

export default function DocumentationLink({ id, target, children }: Props) {
  return (
    <div key="link" className="overflow-hidden">
      <Transition
        show={true}
        appear={true}
        enter="transition-all duration-500 relative delay-150"
        enterFrom="left-[6.35rem]"
        enterTo="left-0"
      >
        <div className="group relative cursor-pointer items-center justify-center">
          <a
            href={`${DOCUMENTATION_URL}${target}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="group flex flex-row flex-nowrap">
              <IconOutline
                id="book-open"
                className="group-hover:text-blueMed h-7 w-7 text-black"
              />
              <Subtitle1Text
                element="span"
                className="group-hover:text-blueMed relative top-[1px] ml-2 text-black"
              >
                {children}
              </Subtitle1Text>
            </div>
          </a>
        </div>
      </Transition>
    </div>
  );
}
