import { Button } from '@components/Button';
import Content from '@components/Content';
import DocumentationLink from '@components/DocumentationLink';
import { Subtitle1Text } from '@components/Text';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { BLOG_URL, DOCUMENTATION_URL, HOMEPAGE_URL } from '@lib';
import Image from 'next/image';
import Link from 'next/link';

interface Props {
  hideNavigation?: boolean;
  helpTarget?: string;
}

const navigation = [
  {
    name: 'How it works',
    href: `${HOMEPAGE_URL}/#how-it-works`,
    newTab: false,
  },
  { name: 'Pricing', href: `${HOMEPAGE_URL}/#pricing`, newTab: false },
  { name: 'Blog', href: `${BLOG_URL}/blog`, newTab: false },
  { name: 'Help Center', href: `${DOCUMENTATION_URL}/docs`, newTab: true },
];

export default function Header({
  hideNavigation = false,
  helpTarget = '',
}: Props) {
  return (
    <>
      <header className="relative z-50 md:z-0">
        <Disclosure as="nav" aria-label="Top" className="bg-white py-6 shadow">
          {({ open }: { open: boolean }) => (
            <>
              <Content>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <Disclosure.Button className="-ml-3 mr-2 inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-greyLt hover:text-blueMed focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent lg:hidden">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                    <Link href={`${HOMEPAGE_URL}/`} className="cursor-pointer">
                      <Image
                        src="https://cdn.offertrackr.com/logo.png"
                        width={170}
                        height={24}
                        alt="OfferTrackr"
                        priority
                      />
                    </Link>
                    {!hideNavigation && (
                      <div className="ml-12 hidden space-x-9 lg:block">
                        {navigation.map(link =>
                          link.newTab || link.href.includes('http') ? (
                            <a
                              key={link.name}
                              href={link.href}
                              target={link.newTab ? '_blank' : '_self'}
                              rel="noreferrer"
                            >
                              <Subtitle1Text element="span">
                                {link.name}
                              </Subtitle1Text>
                            </a>
                          ) : (
                            <Link key={link.name} href={link.href}>
                              <Subtitle1Text element="span">
                                {link.name}
                              </Subtitle1Text>
                            </Link>
                          ),
                        )}
                      </div>
                    )}
                  </div>
                  {!hideNavigation && (
                    <div>
                      <span className="mr-9 hidden md:inline-block">
                        <Link href="/signin">
                          <Subtitle1Text>Sign In</Subtitle1Text>
                        </Link>
                      </span>
                      <span className="inline-block">
                        <Link href="/signin">
                          <Button id="start_trial_header" theme="yellow">
                            <span className="hidden md:block">
                              Try OfferTrackr Free
                            </span>
                            <span className="md:hidden">Get Started</span>
                          </Button>
                        </Link>
                      </span>
                    </div>
                  )}
                  {helpTarget && (
                    <div className="pt-2">
                      <DocumentationLink id={helpTarget} target={helpTarget}>
                        <span className="font-semibold">Help Center</span>
                      </DocumentationLink>
                    </div>
                  )}
                </div>
              </Content>
              <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 pt-3 pb-4">
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                  <Disclosure.Button
                    as="a"
                    href="#how-it-works"
                    className="block border-l-4 border-transparent py-2 pl-5 pr-4 font-Inter text-base font-medium text-black hover:border-blueMed hover:bg-warmWhite hover:text-black"
                  >
                    How it works
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="#pricing"
                    className="block border-l-4 border-transparent py-2 pl-5 pr-4 font-Inter text-base font-medium text-black hover:border-blueMed hover:bg-warmWhite hover:text-black"
                  >
                    Pricing
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href={`${BLOG_URL}/blog`}
                    className="block border-l-4 border-transparent py-2 pl-5 pr-4 font-Inter text-base font-medium text-black hover:border-blueMed hover:bg-warmWhite hover:text-black"
                  >
                    Blog
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href={`${DOCUMENTATION_URL}/docs`}
                    className="block border-l-4 border-transparent py-2 pl-5 pr-4 font-Inter text-base font-medium text-black hover:border-blueMed hover:bg-warmWhite hover:text-black"
                  >
                    Help Center
                  </Disclosure.Button>
                </div>
                <div className=" space-y-1 border-t border-t-greyMed pt-3">
                  <Disclosure.Button
                    as="a"
                    href={`${DOCUMENTATION_URL}/docs`}
                    className="block border-l-4 border-transparent py-2 pl-5 pr-4 font-Inter text-base font-medium text-black hover:border-blueMed hover:bg-warmWhite hover:text-black"
                  >
                    Sign In
                  </Disclosure.Button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </header>
    </>
  );
}
